// fleetilla colors

// primary

$light-primary: #53b4c1;
$light-primary-contrast: #ffffff;

$dark-primary: #53b4c1;
$dark-primary-contrast: #ffffff;

// accent/secondary

$light-accent: #f26522;
$light-accent-contrast: #ffffff;

$dark-accent: #f26522;
$dark-accent-contrast: #ffffff;

// common

$page-background: #53b4c12f;
