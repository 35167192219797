// font variables

$large-font-size: 26px;
$heading-font-size: 17px;
$body-font-size: 15px;
$caption-font-size: 13px;

$light-font-weight: 300;
$normal-font-weight: 400;
$medium-font-weight: 500;
$bold-font-weight: 600;

$font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
